import React from 'react'
import PageHeader from 'src/components/PageHeader'

const NotFoundPage = () => {
    // TODO pageContext with lang and messages are not fetched, because of issues with assigning unique page name
    // TODO since we cant use pageContext here, we cant use Layout comp
    return (
        <>
            <PageHeader>Page not found</PageHeader>
            <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
        </>
    )
}

export default NotFoundPage
